body {
    background-color: #e6e6e6;
    overflow-x: hidden;
}

.join-lobby-div {
    margin-top: 45px;
    margin-left: 50px;
    margin-right: 45px;
}

#challenge-title {
    text-align: center;
    color: red;
    margin-top: 1%;
}

#challenge-desc {
    text-align: center;
    color: grey;
    margin: top 1%;
    font-size: 1.1em;
}

.start-date {
    float: right;
    margin-top: -5%;
    text-align: center;
}

.team-timer {
    float: right;
    margin-top: -5%;
}

#enrollers-table {
    background-color: white;
}

#enrollers-table thead {
    background-color: #EB5C67;
    color:white;
}

#enrollers-table tbody tr td,
#enrollers-table thead tr th {
    text-align: center;
}

#enrollers-table tbody tr:hover {
    background-color: #e6e6e6;
    cursor: pointer;
}

.captain-confirm-icon:hover,
.captain-reject-icon:hover {
    cursor: pointer;
}

.teams-icon {
    font-size: 40px;
    margin-left: 15px;
}

.chat-icon {
    font-size: 40px;
    margin-right: 15px;
}

.chat-icon-teams-icon {
    display: flex;
    justify-content: space-between;
    margin-top: 10%;
}

.chat-icon-teams-icon2 {
    display: flex;
    justify-content: space-between;
}

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid red;
    width: 120px;
    height: 120px;
    margin: 0px auto;
    -webkit-animation: spin 2s linear infinite;
    margin-top: 100px;
    /* Safari */
    animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.questionsection {
    /*box-shadow: rgb(0 0 0 / 20%) -1px 0px 11px 0px;*/
    border: 2px solid;
    border-radius: 10px;
    padding: 16px 32px;
    margin: 16px 0px;
    display: inline-flex;
    font-size: 25px;
}

.classdiv {
    margin: 0px 0.5rem;
    font-weight: 600;
}

.answersdiv {
    height: 344px;
    align-content: center;
    z-index: 1;
    /* margin-left: 100px; */
    overflow-y: scroll;
}

.verticalline {
    border-left: 3px solid #fd404094;
    height: 425px;
    margin: 11px 0px 0px 63px;
    float: left;
}

.answersdiv {
    height: 344px;
    align-content: center;
    z-index: 1;
    /* margin-left: 100px; */
    overflow-y: scroll;
}

.answersdiv_withimage {
    height: 100%;
    align-content: flex-start;
    z-index: 1;
    margin-left: 50px;
}

.answermaindiv {
    font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    position: relative;
    overflow: visible;
    flex-direction: row;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    padding: 0.3125rem;
    /*box-shadow: rgb(0 0 0 / 15%) 0px -0.25rem 0px 0px in;*/
    display: flex;
    flex: 1 0 auto;
    border-radius: 10px;
    /*border: 1px dotted #808080b5;*/
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    cursor: pointer;
    width: 91%;
    background-color: white;
    margin-left: 5%;
    margin-bottom: 2%;
    margin-top: -35px;

}

@media (max-width: 768px) {
    .answermaindiv {
        margin-right: 0px;
    }
}

.card-icon__icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    background: aliceblue;
    height: 50px;
    border-radius: 8px;
    font-size: 25px;
    width: 95%;
    padding: 10px;
    margin-left: -4px;
}

.answertext {
    font-size: 1.3em;
    font-weight: 600;
    line-height: 1.25;
    /*text-shadow: rgb(0 0 0 / 25%) 0px 0.125rem 0px, rgb(0 0 0 / 10%) 0px 0px 0.125rem;*/
    flex: 1 1 calc((100% - 128px) - 0.625rem);
    padding: 0px 0.3125rem;
    text-align: left;
}

.boxshadow {
    opacity: 0.3;
}

.boxshadow1 {
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    border: 2px solid red;
}

.questionsection {
    position: relative;

}

.questionsection:after {
    content: '';
    position: absolute;
    top: 100%;
    /*left: 40%;*/
    border: 30px solid transparent;
    border-top-color: #fd404094;
}

.leaderboard-timer {
    float: right;
    margin-top: -5%;
}

#go-to-home-btn {
    background-color: #FFDFD6 !important;
    color: black;
    border-radius: 25px;
    border: none;
    height: 35px;
    width: 10%;
    float: right;
    margin-top: -2.5%;
}

#go-to-home-btn:hover{
    background-color: #52C3FF !important;
}

.otherTeamsModalWidth {
    min-width: 60%;
}

#teams-table {
    background-color: white;
    margin-top: 0%;
    overflow-y: auto;
    border-radius: 25px;
    overflow: hidden;
}

#teams-table thead {
    background-color: #EB5C67;
    color:white;
}

#teams-table tbody tr td,
#teams-table thead tr th {
    text-align: center;
}


#supportmonboxid {
    position: fixed;
    /* bottom: -7px; */
    width: 25%;
    right: 45px;
    z-index: 1;
}

.supportmonbox_head {
    /* background: #3498db; */
    background-color: rgb(85, 85, 85);
    color: #fff;
    font: 20px Calibri;
    padding: 10px 20px;
    border-radius: 12px 12px 0px 0px !important;
}

.msg_body {
    background: white;
    height: 277px;
    font-size: 12px;
}

#conversation {
    width: 100%;
    height: 50vh;
    border: 1px solid #ccc;
    background-color: white;
    padding: 4px;
    overflow-y: scroll;
    /* display: flex; */
    flex-flow: column wrap;
    justify-content: space-between;
    max-width: 867px;
    /* margin: 25px 10px; */
    border-radius: 5px;
    background: white;
    box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.2);
}

.fadeOut {
    height: 0px;
    bottom: 0px;
    -webkit-transition: height 0.5s linear;
    -moz-transition: height 0.5s linear;
    -ms-transition: height 0.5s linear;
    -o-transition: height 0.5s linear;
    transition: height 0.5s linear;
}

.fadeIn {
    height: 450px;
    bottom: 45px;
    transition: all .3s ease-in-out;
}

.qfadeIn {
    height: 450px;
    bottom: 45px;
    transition: all .3s ease-in-out;
}

.generatingteams-label {
    font-weight: 700;
    text-align: center;
    position: relative;
}

/* @keyframes shine {
    0% {
        background-position-x: -500%;
    }

    100% {
        background-position-x: 500%;
    }
}

@keyframes jump {
    0% {
        transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    }

    100% {
        transform: translate3d(0, 10%, 0) scale3d(1.2, .7, 1);
    }
}

.jump {
    transform-origin: 50% 50%;
    animation: jump 1s linear alternate infinite;
}

.RTE {
    height: 312px;
    margin: 0px 70px 0px 21px;
    border: 7px solid #ddd;
} */

.challenge-environment-gif {
    width: 30%;
    margin-left: 35%;
}

.changeTeamNamemodalWidth {
    width: 30%;
}

#captain-teamname-continue-btn {
    background-color: #FFDFD6 !important;
    color: black;
    border-radius: 25px;
    border: none;
    height: 35px;
}

#challengeCloseAlertModal {
    margin-top: 10%;
}

#modalHomeBtn {
    background-color: #FFDFD6 !important;
    color: black;
    border-radius: 25px;
    border: none;
    height: 35px;
    width: 30%;
    margin-right: 35%;
}

#modalHomeBtn:hover, #captain-teamname-continue-btn:hover{
    background-color: #52C3FF !important;
}

.thankyou-animation {
    height: 175px;
    width: 40%;
    margin-left: 30%;
}

.participant-question-position {
    font-size: 1.3em;
    font-weight: 500;
}