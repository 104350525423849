body {
    background-color: #e6e6e6;
    overflow-x: hidden;
}

.join-lobby-div {
    margin-top: 45px;
    margin-left: 50px;
    margin-right: 45px;
}

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid red;
    width: 120px;
    height: 120px;
    margin: 0px auto;
    -webkit-animation: spin 2s linear infinite;
    margin-top: 100px;
    /* Safari */
    animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.generatingteams-label {
    font-weight: 700;
    text-align: center;
    position: relative;

}

/* @keyframes shine {
    0% {
        background-position-x: -500%;
    }

    100% {
        background-position-x: 500%;
    }
}

@keyframes jump {
    0% {
        transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    }

    100% {
        transform: translate3d(0, 10%, 0) scale3d(1.2, .7, 1);
    }
}

.jump {
    transform-origin: 50% 50%;
    animation: jump 1s linear alternate infinite;
}
.RTE{
    height: 312px;
    margin: 0px 70px 0px 21px;
    border: 7px solid #ddd;
}
.challenge-environment-gif{
    width:30%;
    margin-left:35%;
}
#challengeCloseAlertModal{
    margin-top:10%;
} */
#modalAdminHomeBtn {
    background-color: #FFDFD6 !important;
    color: black;
    border: none;
    height: 35px;
    width: 30%;
    margin-right: 35%;
}

#modalAdminHomeBtn:hover {
    background-color: #52C3FF !important;
}

.thankyou-animation {
    height: 175px;
    width: 40%;
    margin-left: 30%;
}

.viewer-question-position {
    font-size: 1.3em;
    font-weight: 500;
}

.viewer-verticalline {
    border-left: 3px solid #fd404094;
    height: 350px;
    margin: 11px 0px 0px 63px;
    float: left;
}

.viewer-answermaindiv,
#viewer-answermaindiv {
    font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    position: relative;
    overflow: visible;
    flex-direction: row;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    padding: 0.3125rem;
    display: flex;
    flex: 1 0 auto;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    cursor: pointer;
    background-color: white;
    margin-left: 5%;
    margin-bottom: 2%;
    margin-top: -35px;

}

.viewer-answermaindiv2,
#viewer-answermaindiv2 {
    font-family: Montserrat, "Noto Sans Arabic", "Helvetica Neue", Helvetica, Arial, sans-serif;
    position: relative;
    overflow: visible;
    flex-direction: row;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    padding: 0.3125rem;
    display: flex;
    flex: 1 0 auto;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    cursor: pointer;
    background: linear-gradient(to right, rgb(244, 244, 1), rgb(185, 228, 1));

    /* background-color:red;  */
    margin-left: 5%;
    margin-bottom: 2%;
    margin-top: -35px;

}

@media (max-width: 768px) {

    .viewer-answermaindiv,
    .viewer-answermaindiv2 {
        margin-right: 0px;
    }
}

.chat-icon-teams-icon2 {
    display: flex;
    justify-content: space-between;
    margin-top: 1%;
}

.sub-answers{
    margin-left: 10%;
    margin-top: 1.5%;
}

.viewer-sub-answer-div {
    padding: 5px 10px 5px 10px;
    border: 2px solid #e6e6e6;
    background-color: #e6e6e6;
    color: black;
    border-radius: 15px;
    margin:10px 5px 10px 5px;
}