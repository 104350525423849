.start-challenge-lobby-div {
    height: 550px;
    margin-top: 45px;
    border-radius: 25px;
    border: solid 2px red;
    background-color: white;
    margin-left: 50px;
    margin-right: 45px;
}

.challenge-title {
    text-align: center;
    color: red;
    margin-top: 1%;
}

.challenge-desc {
    text-align: center;
    color: grey;
    margin: top 1%;
}

#open-challenge-btn {
    margin-top: 2.5%;
    width: 20%;
    margin-left: 40%;
    background-color: red;
    color: white;
}

.time-div {
    margin-top: 5%;
}

.time-div .time-label {
    color: #868786;
    text-align: center;
}

.time-div .org-time {
    text-align: center;
}

#challenge-alert-label{
    text-align: center;
    margin-top: 2%;
    width:60%;
    margin-left: 20%;
    margin-bottom: -10px;
    color:grey;
}
.participants-refresh:hover{
    cursor:pointer;
}
.leaderboard-background{
    background: linear-gradient(to right, rgb(253, 243, 194), rgb(255, 194, 213));
}