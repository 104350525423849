.final-leaderboard-div {
    /* height: 550px; */
    margin-top: 45px;
    border-radius: 25px;
    /* border: solid 2px red;
    background-color: white; */
    margin-left: 40px;
    margin-right: 35px;
}

.leaderboard-details {
    margin-top: 10px;
}

#go-home-btn {
    background-color: #FFDFD6 !important;
    color: black;
    border-radius: 25px;
    border: none;
    height: 35px;
    width: 10%;
    float: right;
    margin-right: 10px;
    margin-top: 5px;
}

#challengeCloseAlertModal {
    margin-top: 10%;
}

#modalAdminHomeBtn {
    background-color: #FFDFD6 !important;
    color: black;
    border-radius: 25px;
    border: none;
    height: 35px;
    width: 30%;
    margin-right: 35%;
}

#go-home-btn:hover,
#modalAdminHomeBtn:hover {
    background-color: #52C3FF !important;
}

.thankyou-animation {
    height: 175px;
    width: 40%;
    margin-left: 30%;
}