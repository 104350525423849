.subject-feedback-page {
  background-color: #ffffff;
}

#show {
  border-radius: 25px;
  color: black !important;
  border: none;
  width: 80px;
  margin-left: 20px;
  background-color: #FFDFD6 !important;
}

#showSelected {
  border-radius: 25px;
  color: black !important;
  border: none;
  width: 80px;
  margin-left: 20px;
  background-color: #52C3FF !important;
}

#show2 {
  border-radius: 25px;
  color: black !important;
  border: 1px solid #FFDFD6;
  width: 80px;
  margin-left: 20px;
  background-color: #e6e6e6;
}

#show:hover,
#showSelected,
#show2:hover,
#next:hover,
#addnewcategory:hover {
  background-color: #52C3FF !important;
}

.createFeedback .select__value-container {
  min-height: 45px;
  width: 100%;
}

#addnewcategory {
  height: 40px;
  border-radius: 25px;
  color: black !important;
  border: none;
  width: 100%;
  background-color: #FFDFD6 !important;
}

#next {
  border-radius: 25px;
  color: black !important;
  border: none;
  background-color: #FFDFD6 !important;
  width: 80%;
  height: 40px;
  margin-top: 10px;
}

#addNewCatBtn {
  border-radius: 25px;
  color: black !important;
  border: none;
  background-color: #FFDFD6;
  width: 25%;
}

#addNewCatModalCloseBtn {
  border-radius: 25px;
  border: none;
  width: 25%;
}

#addNewCatBtn:hover {
  background-color: #52C3FF !important;
}

#addNewCatModalCloseBtn:hover {
  background-color: #e6e6e6;
  color: black;
}

.subject-feedback-creation-div #pageLabel {
  font-size: 1.8rem;
}

#feedback-description {
  height: 100px;
  resize: none;
}

.createFeedback label {
  font-size: 1.15rem;
  margin-top: 15px;
}