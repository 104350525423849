.challenge-questionnaire-div {
    height: 640px;
    margin-top: 20px;
    border-radius: 25px;
    /* border: solid 2px red;
    background-color: white; */
    margin-left: 40px;
    margin-right: 35px;
    overflow-x: hidden;
}

.questionpanel-challenge-title {
    color: red;
    margin-top: 1%;
    float: left;
    margin-left: 2.5%;
}

.question-info {
    border: 3px solid #878686;
    padding: 10px;
    width: 90%;
    margin-left: 2%;
    border-radius: 10px;
    font-size: 1.4em;
    font-weight: 400;
}

.options-info {
    border: 2px solid #e6e6e6;
    padding: 10px;
    margin-left: 2.75%;
    width: 95%;
    margin-top: 1.75%;
    border-radius: 10px;
    font-size: 1.25em;
}

.options-info:hover {
    border: 2px solid red;
    cursor: pointer;
}

::-webkit-scrollbar {
    width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

#getnext-question-btn {
    background-color: #FFDFD6 !important;
    color: black;
    border-radius: 25px;
    border: none;
    float: right;
    margin-right: 2.5%;
    width: 15%;
    font-size: 1.1em;
    margin-top: -20px;
}

#getnext-question-btn:hover,#showLeaderBoardBtn:hover {
    background-color: #52C3FF !important;
}

.challengeSpinWheelmodalWidth {
    width: 30%;
}

#showLeaderBoardBtn {
    background-color: #FFDFD6 !important;
    color: black;
    border-radius: 25px;
    border: none;
    float: right;
    margin-top: 20px;
    height: 40px;
}

.challengeleaderboardmodalWidth {
    min-width: 90%;
}

.question-image {
    max-width: 90%;
    min-width: 50%;
    margin-top: 10%;
}

.option-image {
    max-width: 60%;
    min-width: 20%;
    margin-left: 20%;
    margin-top: 2.5%;
}

.admin-verticalline {
    border-left: 3px solid #fd404094;
    height: 360px;
    margin: -25px 10px 0px 77px;
    float: left;
}

.carrotdown-icon {
    font-size: 5em;
    padding: 0;
    margin: 0;
    margin-left: 38px;
    margin-top: -20px;
    color: #fd404094;
    position: relative;
    z-index: 2;
}

.challengeleaderboardAlertmodalWidth {
    width: 30%;
}

.challengeQuestionTimermodalWidth {
    width: 30%;
}

#closeTimerModalBtn {
    background-image: linear-gradient(131deg, #7175E0 0%, #4D50CB 33%, #E25BC8 100%);
    border-radius: 25px;
    border: none;
    float: right;
}