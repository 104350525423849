.show-feedback-results label {
    font-weight: 500;
}

#donwloadResultsBtn {
    border-radius: 25px;
    background-color: #FFDFD6;
    border: none;
    color: black;
    width: 100%;
}

#donwloadResultsBtn:hover {
    background-color: #52C3FF !important;
}

.label-value {
    font-size: 17px;
}

.t-label {
    font-size: 17px;
    font-weight: 500;
}

#fedbackListTable {
    border: 1px solid grey;
}

/* #fedbackListTable .thead1 {
    background-color: #EB5C67;
    color: white;
} */

#fedbackListTable .thead2 {
    /* background-color: #FFDFD6; */
    background-color: #e6e6e6;
    color: black;
}

/* .table-faculty-name {
    background-color: #EB5C67;
    padding: 10px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
} */

.reports-generate-gif {
    width: 50%;
    height: 400px;
    margin-left: 25%;
}

.generating-reports-label{
    font-size: 20px;
    text-align: center;
    margin-top: -50px;
    font-weight: 500;
}

.field-label{
    font-weight: 550;
    font-size: 17px;
}

.inplace-reports-gif {
    width: 15%;
    height: 150px;
    margin-left: 41%;
    margin-bottom: 80px;
    margin-top: 100px;
}