#pageTitle {
  word-break: break-all;
  font-family: "Source Sans Pro";
}

.internal-padding {
  padding: 0 1rem;
}

.feedbacks-headerBanner {
  position: relative;
  padding-bottom: 10px;
  width: 97.5%;
  margin-right: 5%;
}

.feedbacks-headerBanner h2 p {
  font-size: 1.8rem;
  margin-bottom: 0px;
}

.titlehr {
  width: 5rem;
  height: 4px;
  text-align: start;
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 15px;
  background: linear-gradient(131deg, #7175E0 0%, #4D50CB 33%, #E25BC8 100%);
  border-radius: 5px;
}

#dropdown-basic {
  border-radius: 25px;
  color: black !important;
  border: none;
  background-color: #FFDFD6 !important;
}

#dropdown-basic:hover {
  background-color: #52C3FF !important;
}