.weekly-Arena-form {
    text-align: center;
    padding: 20px;
}

.Arena-title {
    font-size: 2.15em;
}

.edit_challenge-title{
    font-size:45px;
}

.editPencil {
    font-size: 1em;
    margin-left: 15px;
    margin-top: -12px;
}

.editPencil:hover,
.editdescPencil:hover,
.challenge_frequency:hover,
.sub_frequency:hover {
    cursor: pointer;
}

.editdescPencil {
    font-size: 1em;
    margin-left: 15px;
    margin-top: -5px;
}

.Arena-date {
    font-size: 2em;
}
.edit_challenge-date{
    font-size:18px;
}

.challenge_frequency,
.sub_frequency {
    width: 20px;
    height: 20px;
    margin-left: 5%;
}

.challenge_frequency_txt,
.challenge_subfrequency_txt {
    font-size: 1.1em;
    margin: 10px;
    margin-top: -20px;
}

.weeklyChallengemodalWidth {
    min-width: 80%;
}

.custom-weeklyChallenge-form {
    padding: 25px;
}

#saveWeeklyChallengeChangesBtn {
    border-radius: 25px;
    background-color: #FFDFD6;
    color: black;
    border: none;
    width: 15%;
}

#saveWeeklyChallengeChangesBtn:hover,
#saveChallengeCoordinatorsBtn:hover,#saveChallengeBtn:hover,
#addCoordinatorsBtn:hover,
#chooseChallengeCoordinatorsBtn:hover,
#Arena-customize-continue-btn:hover,
#saveChallengeCoordinatorsBtn:hover  {
    background-color: #52C3FF;
}

#modalCloseBtn {
    border-radius: 25px;
}

.custom-weeklyChallenge-form {
    margin-top: -40px;
    line-height: normal;
}

.custom-coordinators-form {
    margin-top: -15px;
}

.custom-weeklyChallenge-form div label,
.custom-coordinators-form div div label {
    font-size: 1em;
    margin-top: 10px;
}

.custom-weeklyChallenge-form .form-control,
.custom-coordinators-form .form-control {
    margin-top: 5px;
    padding: 10px;
}

#saveChallengeBtn {
    border-radius: 25px;
    background-color: #FFDFD6;
    color:black;
    border: none;
}

#addCoordinatorsBtn,
#Arena-customize-continue-btn {
    border-radius: 25px;
    background-color: #FFDFD6;
    color:black;
    border: none;
    margin-right: 10px;
}
#chooseChallengeCoordinatorsBtn{
    border-radius: 25px;
    background-color: white;
    color:black;
    border: 1px solid #FFDFD6;
    margin-right: 10px;
}
.addCoordinatorsmodalWidth {
    min-width: 50%;
}

.challengeCustomAlertmodalWidth {
    min-width: 30%;
}
#challengeCreateAlertModal{
    min-width:20%;
    margin-top: 10%;
    height:400px;
}

#Arena-custom-msg-label {
    font-size: 1.2em;
    width: 80%;
    margin-left: 10%;
    text-align: center;
}

.weekly-Arena-checkbox {
    width: 20px;
    height: 20px;
}

.weekly-Arena-all-checkbox {
    width: 15px;
    height: 15px;
    margin-left: 10px;
    margin-bottom: -15px;
}

.category-div label {
    float: left;
    padding: 10px;
    width: 23%;
    margin-right: 1%;
    margin-left: 1%;
    border: solid 2px #e6e6e6;
    background-color: white;
    border-radius: 25px;
    text-align: center;
}
.choosingquestions label,.enroll-div label,.participation-div label {
    float: left;
    padding: 10px;
    width: 27.5%;
    margin-right: 2.5%;
    margin-left: 2.5%;
    border: solid 2px #e6e6e6;
    background-color: white;
    border-radius: 25px;
    text-align: center;
}

.custom-input-label{
    font-weight: 500;
}

.category-div label:hover,
.choosingquestions label:hover,
.enroll-div label:hover,
.participation-div label:hover {
    cursor: pointer;
    background-color:#52C3FF;
    font-size:17px;
}

#col-form-label {
    margin-top: -20px;
}

#Arena-coordinators-table {
    background-color: white;
    border-radius: 25px;
    overflow: hidden;
}

#Arena-coordinators-table thead {
    background-color: #EB5C67;
}

#Arena-coordinators-table tbody tr td,
#Arena-coordinators-table thead tr th {
    text-align: center;
}

.category-div .input-selected,
.timeperquestion-div .input-selected,
.choosingquestions .input-selected,.enroll-div .input-selected,
.participation-div .input-selected {
    background-color: #EB5C67;
    color: white;
}

.noofregistrations-div label {
    float: left;
    width: 50%;
}

.noofregistrations-div input {
    width: 39%;
}

.teamsize-div {
    margin-top: 20px;
}

.teamsize-div label {
    float: left;
    width: 30%;
}

.teamsize-div input {
    width: 30%;
}

.timeperquestion-div label {
    float: left;
    padding: 5px;
    width: 27.5%;
    margin-right: 2.5%;
    margin-left: 2.5%;
    border: solid 2px #e6e6e6;
    background-color: white;
    border-radius: 25px;
    text-align: center;
}

.timeperquestion-div label:hover {
    cursor: pointer;
}

.noofquestions-div {
    margin-top: 20px;
}

.departments-div label,
.semesters-div label {
    margin-bottom: 5px;
}

.timer-icon {
    width: 100%;
    font-size: 1.3em;
    font-weight: 500;
}

#saveChallengeCoordinatorsBtn {
    border-radius: 25px;
    background-color: #FFDFD6;
    color:black;
    border: none;
    width: 40%;
}

#no-coordinators-msg-label2 {
    text-align: center;
}

.loading {
    font-weight: bold;
    display: inline-block;
    font-family: monospace;
    font-size: 50px;
    clip-path: inset(0 9ch 0 0);
    animation: l 1s steps(4) infinite;
    width: 150px;
    color:#EB5C67;
    margin-top: -25px;
    text-align: center;
}

@keyframes l {
    to {
        clip-path: inset(0 -1ch 0 0)
    }
}
.loading-div-label{
    text-align: center;
    font-weight: bold;
    font-size: 1.5em;
}
.Arena-success-label{
    text-align: center;
    font-weight: bold;
    font-size: 1.5em;
    margin-top: 50px;
}
#success-tick-icon{
    font-size: 4em;
    color:green;
    text-align: center;
}
.input-save-btn{
    font-size: 1.3em;
}
#edit-add-departments-btn{
    border-radius: 25px;
    margin-bottom: -5%;
    background-color: #FFDFD6;
    color: black;
    border:none;
}
#edit-add-departments-btn:hover{
    background-color: #52C3FF;
}