/* .navbar-div{
    max-width: 13% !important;
    padding: 0;
    }
  @media only screen and (max-width: 990px) {
    .sideNav,.navbar-div{
        max-width: 19% !important;
    }
} */



.sideNav{
    top: 10%;
    left: 0;
    bottom: 0;
    position: absolute;
    /* position: fixed; */
    /*overflow-y: scroll;*/
    overflow-x: hidden;
    padding: 0 0 10px 10px;
    /* max-width: 12%;    */
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */   
    /* font-family: "Source Sans Pro";  */
}

@media only screen and (max-height: 650px) {
    .sideNav{
        top: 14% !important;
    }
}


.navContainer{
    max-width: 95%;
    margin: 8px 0 8px 0;
    background-color: white;
    border-radius: 15px;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
    box-shadow: 0 0 1px 2px rgb(0 0 0 / 17%);
    padding: 10px 0 10px 5px;
}


.sidebarLinks{
    /*border-bottom: 1px solid #ebebeb;*/
    padding: 7px 10px;
    width: 100%;
    font-size: 13px;
}

/*.sidebarLinks.active{
    background: linear-gradient(136deg ,#DCFF73 0%, #8ECA6B 100%);
    width: 100%;
    border-radius: 20px;
    padding:10px;
    color:black !important;
}*/

.sidebarLinks.active > .sidebarNav{
    color:black !important;
    font-size: 15px !important;
}

/*.fas.fa-caret-right{
    right: 20px;
}*/

.sidebarNav{
    color: #535454;
    font-size: 15px;
    text-decoration: none;
}

.sidebarNav.sidebarLinks.active{
    color: black;
}

.sidebarNav{
    color: #535454;
    font-size: 15px;
}

.sidebarNav.sidebarLinks.active{
    color: black;
}

.sidebarNav:hover{
    color: black;
    text-decoration: none;
}

.selectedNav{
    font-size: larger;
}


.sidebarHelpLink{
    color: #3d3dff;
    text-decoration: underline;
}

.sidebarHelpLink i{
    height: 5px;
}

.sidebarOtherLinks{
    border-bottom: 1px solid #ebebeb;
    padding: 10px;
    width: 100%;
    font-size: 15px;
}


.navtitlehr{
    width: 50%;
    height: 3px;
    text-align: start;
    margin: 0;
    background-image: linear-gradient(131deg, #7175E0 0%, #4D50CB 33%, #E25BC8 100%);
    border-radius: 5px;
    opacity: 70%;
  }

  /* .a:link { text-decoration: none; }
  .a{
    text-decoration: none !important;
  }
  
  .a:visited { text-decoration: none; } */
