.generate-teams-div {
    margin-top: 30px;
    border-radius: 25px;
    margin-left: 40px;
    margin-right: 35px;
    padding:10px;
}
#start-challenge-btn2{
    margin-bottom:15px;
    margin-top:10px;
    width: 20%;
    margin-left: 40%;
    background-color: red;
    color: white;
    height:50px;
}