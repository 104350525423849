.feedback-inner-card {
    padding: 15px;
    border: 1px #e6e6e6 solid;
    border-radius: 25px;
    width: 101%;
    float: right;
    background-color: white;
    border-left: 10px solid #FFDFD6;
}

.general-feedback-inner-card {
    padding: 15px;
    border: 1px #e6e6e6 solid;
    border-radius: 25px;
    width: 101%;
    float: right;
    background-color: white;
    border-left: 10px solid #52C3FF;
}

#feedback-card {
    margin-top: 2%;
}

#view-feedback-details-btn {
    border-radius: 25px;
    background-color: #FFDFD6 !important;
    color: black;
    border: none;
    width: 40%;
}

#view-feedback-details-btn2 {
    border-radius: 25px;
    background-color: #FFDFD6 !important;
    color: black;
    border: none;
    width: 100%;
    float: right;
}

#view-feedback-details-btn3 {
    border-radius: 25px;
    background-color: #FFDFD6 !important;
    color: black;
    border: none;
    width: 80%;
    float: right;
    font-size: 0.8em;
    padding: 1px;
}

#editFeedbackTimeBtn{
    border-radius: 25px;
    background-color: #FFDFD6 !important;
    color: black;
    border: none;
    width: 120px;
}

#view-feedback-details-btn:hover,
#view-feedback-details-btn2:hover,
#view-feedback-details-btn3:hover,
#editFeedbackTimeBtn:hover {
    background-color: #52C3FF !important;
}

.feedback-inner-card2 {
    padding: 10px;
    border: 1px #e6e6e6 solid;
    border-radius: 25px;
    width: 100%;
    float: right;
    background-color: #ffffff;
    border-left: 10px solid #75b1e8;
    margin-top: 10px;
}

.inner-feedback-card {
    margin-top: 2%;
}

#feedback-button-clicked {
    border-radius: 25px;
    background-color: #52C3FF !important;
    color: black;
    border: none;
    width: 40%;
}

.pdf-icon {
    color: #f40f02;
    font-size: 30px;
    cursor: pointer;
    padding: 5px;
}

.excel-icon {
    color: #1d6f42;
    font-size: 30px;
    margin-left: 10px;
    cursor: pointer;
    padding: 5px;
}

.pdf-icon:hover,
.excel-icon:hover {
    border: 2px solid #e6e6e6;
    border-radius: 50%;
}

.cardTimings {
    font-size: 13px;
    color: grey;
}

#odonwloadResultsBtn {
    border-radius: 25px;
    background-color: white;
    border: 2px solid #FFDFD6 !important;
    color: black;
    width: 80%;
    margin-left: 10%;
}

.cardInprogressLabel {
    font-size: 16px;
    color: black;
    margin-top: 1px;
}

#viewMoreLabel {
    cursor: pointer;
    padding: 5px;
    font-size: 12px;
    color: #284af7;
}

#viewMoreLabel:hover {
    text-decoration: underline;
}

#editLabel {
    cursor: pointer;
    padding: 5px;
    font-size: 16px;
    color: #284af7;
    text-align: center;
}

#editLabel:hover {
    text-decoration: underline;
}

#percentageLabel{
    font-size: 10px;
    border: 2px solid #FFDFD6;
    background-color: white;
    color: black;
    margin-left: 27.5%;
    display: block;
}