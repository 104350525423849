.weekly-challenge-form {
    text-align: center;
    padding: 10px;
}

.create_challenge-title {
    font-size: 45px;
}

.editPencil {
    font-size: 1em;
    margin-left: 15px;
    margin-top: -12px;
}

.editPencil:hover,
.editdescPencil:hover,
.challenge_frequency:hover,
.sub_frequency:hover {
    cursor: pointer;
}

.editdescPencil {
    font-size: 1em;
    margin-left: 15px;
    margin-top: -5px;
}
.challenge-desc{
    line-height:3;
}

.create_challenge-date {
    font-size: 18px;
}

.create_challenge_frequency,
.create_sub_frequency {
    width: 17px;
    height: 17px;
    margin-left: 5%;
}

.challenge_frequency_txt,
.challenge_subfrequency_txt {
    font-size: 1.1em;
    margin: 10px;
    margin-top: -20px;
}

.weeklyChallengemodalWidth {
    min-width: 80%;
}

.custom-weeklyChallenge-form {
    padding: 25px;
}

#create_saveWeeklyChallengeChangesBtn {
    border-radius: 25px;
    background-color: #FFDFD6;
    color: black !important;
    border: none;
    width: 15%;
}
#create_saveWeeklyChallengeChangesBtn:hover{
    background-color: #52C3FF;
}

#modalCloseBtn {
    border-radius: 25px;
    border:none;
    background-color: #e6e6e6;
    color:black !important;
}
#modalCloseBtn:hover{
    background-color: #52C3FF !important;
    border:none;
}

.custom-weeklyChallenge-form {
    margin-top: -40px;
    line-height: normal;
}

.custom-coordinators-form {
    margin-top: -15px;
}

.custom-weeklyChallenge-form div label,
.custom-coordinators-form div div label {
    font-size: 1em;
    margin-top: 10px;
}
.custom-input-label{
    font-weight: 500;
}
.custom-weeklyChallenge-form .form-control,
.custom-coordinators-form .form-control {
    margin-top: 5px;
    padding: 10px;
}

#create_ChallengeBtn {
    border-radius: 25px;
    /* background-image: linear-gradient(131deg, #7175E0 0%, #4D50CB 33%, #E25BC8 100%); */
    background-color: #FFDFD6 !important;
    border: none;
    color: black;
}
#create_ChallengeBtn:hover,
#addCoordinatorsBtn:hover,
#create_chooseChallengeCoordinatorsBtn:hover,
#create-challenge-customize-continue-btn:hover,
#create_saveChallengeCoordinatorsBtn:hover{
    background-color: #52C3FF !important;
}

#addCoordinatorsBtn,
#create-challenge-customize-continue-btn,#create_saveChallengeCoordinatorsBtn {
    border-radius: 25px;
    /* background-image: linear-gradient(131deg, #7175E0 0%, #4D50CB 33%, #E25BC8 100%); */
    background-color: #FFDFD6 !important;
    color: black !important;
    border: none;
    margin-right: 10px;
}
#create_chooseChallengeCoordinatorsBtn{
    border-radius: 25px;
    /* background-image: linear-gradient(131deg, #7175E0 0%, #4D50CB 33%, #E25BC8 100%); */
    border:1px solid #FFDFD6 !important;
    background-color: white;
    color: black !important;
    border: none;
    margin-right: 10px;
}

.addCoordinatorsmodalWidth {
    min-width: 50%;
}

.challengeCustomAlertmodalWidth {
    min-width: 30%;
}

#challengeCreateAlertModal {
    min-width: 20%;
    margin-top: 10%;
    height: 400px;
}

#challenge-custom-msg-label {
    font-size: 1.2em;
    width: 80%;
    margin-left: 10%;
    text-align: center;
}

.weekly-challenge-checkbox {
    width: 20px;
    height: 20px;
}

.weekly-challenge-all-checkbox {
    width: 15px;
    height: 15px;
    margin-left: 10px;
    margin-bottom: -15px;
}

.create_category-div label{
    float: left;
    padding: 10px;
    width: 23%;
    margin-right: 1%;
    margin-left: 1%;
    border: solid 2px #e6e6e6;
    background-color: white;
    border-radius: 25px;
    text-align: center;
}
.choosingquestions label {
    float: left;
    padding: 10px;
    width: 27.5%;
    margin-right: 2.5%;
    margin-left: 2.5%;
    border: solid 2px #e6e6e6;
    background-color: white;
    border-radius: 25px;
    text-align: center;
}

.create_category-div label:hover,
.choosingquestions label:hover {
    cursor: pointer;
}

#col-form-label {
    margin-top: -20px;
}

#challenge-coordinators-table {
    background-color: white;
    border-radius: 25px;
    overflow: hidden;
}

#challenge-coordinators-table thead {
    background-color: #EB5C67;
    color:white;
}

#challenge-coordinators-table tbody tr td,
#challenge-coordinators-table thead tr th {
    text-align: center;
}

.create_category-div label:hover,
.timeperquestion-div label:hover,
.choosingquestions label:hover{
    background-color: #52CEFF !important;
    color:black;
    font-size:17px;
}

.create_category-div .input-selected,
.timeperquestion-div .input-selected,
.choosingquestions .input-selected {
    background-color: #EB5C67;
    color: white;
}

.create_noofregistrations-div label {
    float: left;
    width: 50%;
}

.create_noofregistrations-div input {
    width: 39%;
}

.create_teamsize-div {
    margin-top: 20px;
}

.create_teamsize-div label {
    float: left;
    width: 30%;
}

.create_teamsize-div input {
    width: 30%;
}

.timeperquestion-div label {
    float: left;
    padding: 5px;
    width: 27.5%;
    margin-right: 2.5%;
    margin-left: 2.5%;
    border: solid 2px #e6e6e6;
    background-color: white;
    border-radius: 25px;
    text-align: center;
}

.timeperquestion-div label:hover {
    cursor: pointer;
}

.create_noofquestions-div {
    margin-top: 20px;
}

.departments-div label,
.semesters-div label {
    margin-bottom: 5px;
}

.timer-icon {
    width: 100%;
    font-size: 1.3em;
    font-weight: 500;
}

#no-coordinators-msg-label2 {
    text-align: center;
}

.loading {
    font-weight: bold;
    display: inline-block;
    font-family: monospace;
    font-size: 50px;
    clip-path: inset(0 9ch 0 0);
    animation: l 1s steps(4) infinite;
    width: 150px;
    color: #EB5C67;
    margin-top: -25px;
    text-align: center;
}

@keyframes l {
    to {
        clip-path: inset(0 -1ch 0 0)
    }
}

.loading-div-label {
    text-align: center;
    font-weight: bold;
    font-size: 1.5em;
}

.challenge-success-label {
    text-align: center;
    font-weight: bold;
    font-size: 1.5em;
    margin-top: 50px;
}

#success-tick-icon {
    font-size: 4em;
    color: green;
    text-align: center;
}

.degdeprt-outer-div {
    border: 1.5px solid #e6e6e6;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    margin-top: 2.5%;
}

.degdept-name {
    background-color: #EB5C67;
    color:white;
    border-radius: 10px;
    padding: 5px;
    width: 100%;
}

.degdept-sem {
    background-color: #e6e6e6;
    border-radius: 10px;
    padding: 5px;
    margin-top: 5px;
    width: 100%;
}
.degdept-sem:hover,.deg-department-semesters-div .input-selected:hover{
    background-color: #52C3FF;
    color:black;
    font-size:17px;
}
.deg-department-semesters-div .input-selected {
    background-color: #EB5C67;
    color: white;
}

.row.no-gutters {
    margin-left: 0;
    margin-right: 0;
}

.row.no-gutters [class*='col-']:not(:first-child),
.row.no-gutters [class*='col-']:not(:last-child) {
    padding-right: 0;
    padding-left: 0;
}
.delDeptSemDataItem{
    background-color: #f9f9f9;
    border-radius: 10px;
    padding: 5px;
    width: 100%;
    margin-left: 4px;
    text-align: center;
}
.delDeptSemDataItem:hover{
    cursor:pointer;
    background-color: #EB5C67;
    color:white;
}
#create-add-departments-btn{
    border-radius: 25px;
    margin-bottom: -5%;
    background-color: #FFDFD6;
    color: black;
    border:none;
}
#selectDeptFileBtn{
    border-radius: 25px;
    background-color: #FFDFD6;
    color: black;
    border: none;
    margin-left: 5%;
    margin-top:-1px;
}
#downloadDeptTemplateBtn{
    border-radius: 25px;
    border:1px solid #FFDFD6;
    background-color: white;
    color: black;
    margin-top:-1px;
    margin-left:5%;
}
#create-add-departments-btn:hover,
#selectDeptFileBtn:hover,
#closeErrorAlertModalBtn:hover,
#downloadDeptTemplateBtn:hover{
    background-color: #52C3FF;
}
.input-save-btn{
    font-size: 1.3em;
}
#closeErrorAlertModalBtn{
    border-radius: 25px;
    background-color: #FFDFD6;
    color: black;
    border:none;
    width:25%;
}
.help-div{
    font-weight:500;
    margin-right:2.5%;
    font-size:16px;
    cursor:pointer;
    padding:5px 15px 5px 15px;
    border-radius: 25px;
    background-color: #e6e6e6;
}
.help-div:hover{
    background-color: #52C3FF;
}
.help-content label{
    font-size:14px;
    font-weight:500;
}
.help-content p{
    font-size: 13px;
}