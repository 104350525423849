.leaderboardrow {
    border-radius: 10px;
    display: flex;
    font-size: xx-large;
    color: black;
    /*font-family: cursive;*/
    position: relative;
}
.leaderboardrow-col{
    font-weight: 700;
}
.questiondetails {
    margin-top: 5px;
    border-radius: 10px;
}

.detailedreport {
    height: 400px;
    background-color: #0000ff2e;
    /* background: linear-gradient(204deg, rgba(255,0,0,.8),rgba(255,0,0,0) 70.71%),
                linear-gradient(127deg, rgba(0,255,0,.8),rgba(0,255,0,0) 70.71%),
                linear-gradient(336deg, rgb(167, 181, 246),rgba(0,0,255,0) 70.71%),
                linear-gradient(336deg, rgb(201 213 21), rgba(0,0,255,0) 70.71%),
                linear-gradient(345deg, rgb(253, 206, 232), rgba(0,0,255,0) 70.71%);*/
    /*background:linear-gradient(139deg, #188fa3,#451f45e0 70.71%);*/
    border-radius: 10px;
    background: linear-gradient(157deg, #ed0d0d54, #d1161647 69.71%);
}

.teamreport {
    text-decoration: underline;
    font-size: 20px;
    color: black;
    text-align: start;
    font-weight: 500;
}

.questionstat {
    text-decoration: underline;
    font-size: 18px;
    color: black;
    text-align: start;
    font-weight: 500;
}

.teamstat {
    padding: 0px;
    color: black;
    text-align: start;
}

.numberCircle {
    border-radius: 34px;
    width: 34px;
    height: 34px;
    padding: 5px;
    background: #fff;
    border: 2px solid #666;
    color: #666;
    text-align: center;
    font: 20px Arial, sans-serif;
    margin: 4px;
}

.showdetailedreport {
    padding: 10px;
}

.questionsection_review {
    /* box-shadow: rgb(0 0 0 / 20%) -1px 0px 11px 0px; */
    border: 1px solid;
    border-radius: 10px;
    padding: 10px 10px;
    /* margin: 16px 0px; */
    display: inline-flex;
    font-size: 20px;
    width:100%;
}

.classdiv {
    margin: 0px 0.5rem;
    font-weight: 600;
    padding-left: 10px;
}

.answersdiv_review {
    align-content: center;
    z-index: 1;
    margin-top: 40px;
    width:50%;
}

@media (max-width: 768px) {
    .answermaindiv {
        margin-right: 0px;
    }
}

.card-icon__icon2 {
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    background: aliceblue;
    height: 65px;
    border-radius: 8px;
    font-size: 25px;
    width: 95%;
    padding: 12px;
    margin-left: -4px;
}

.answertext {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.25;
    /*text-shadow: rgb(0 0 0 / 25%) 0px 0.125rem 0px, rgb(0 0 0 / 10%) 0px 0px 0.125rem;*/
    flex: 1 1 calc((100% - 128px) - 0.625rem);
    padding: 0px 0.3125rem;
    text-align: left;
    /* z-index: 90; */
}