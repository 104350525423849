#submitimg {
    width: 20%;
    margin-left: 40%;

}

#homebtn {
    width: 10%;
    margin-left: 45%;
    margin-top: 2.5%;
    border-radius: 25px;
    background-color: #FFDFD6;
    border: none;
    color: black;
}

#homebtn:hover {
    background-color: #52C3FF !important;
    font-size: 17px;
}