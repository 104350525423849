#pageName{
    word-break: break-all;
    font-family: "Source Sans Pro";
    font-size: 1.8rem;
}

#downloadSummaryBtn{
    border-radius: 25px;
    background-color: #FFDFD6 !important;
    color: black;
    border: none;
}

#downloadSummaryBtn:hover{
    background-color: #52C3FF !important;
}