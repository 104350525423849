.mt-1{
    margin-top: 0.25rem !important;
}
#courseName{
    word-break: break-all;
    font-family: "Source Sans Pro";
}
.internal-padding{
    padding: 0 1rem;
}
.headerBanner{
    position:relative;
    padding-top: 20px;
    padding-bottom:10px;
    width: 101%;
}
.headerBanner h2 p{
    font-size: 1.8rem;
    margin-bottom: 0px;
}
.titlehr{
    width: 5rem;
    height:4px;
    text-align: start;
    margin-left:0;
    margin-top: 0;
    margin-bottom: 15px;
    background: linear-gradient(131deg, #7175E0 0%, #4D50CB 33%, #E25BC8 100%);
    border-radius: 5px;
    opacity: 100%;
}
#createChallengeBtn{
    border-radius:25px;
    background-color: #FFDFD6 !important;
    color: black !important;
    border:none;
    margin-right: 0;
}
#addGeneralCoordinatorsBtn{
    border-radius:25px;
    background-color: white;
    color: black !important;
    border: 1px solid #FFDFD6 !important;;
    margin-right: 25px;
}
.no-challenges-msg-label{
    margin-top: 5%;
    padding:25px;
    font-size: 1.25em;
}
#general-coordinators-table{
    background-color: white;
    margin-top: 0%;
    overflow-y: auto;
    border-radius: 25px;
    overflow: hidden;
}
#general-coordinators-table thead{
    background-color: #EB5C67;
    color:white;
}
#general-coordinators-table tbody tr td,#general-coordinators-table thead tr th{
    text-align: center;
}
.addClgCoordinatorsmodalWidth{
    min-width:40%;
    min-height:50%;
}
.saveClgCoordinatorsmodalWidth{
    min-width:50%;
    min-height:50%;
}
#addnewChallengeCoordinatorsBtn{
    border-radius:25px;
    background-color: #FFDFD6 !important;
    color: black !important;
    border:none;
    margin-left: 80px;
    margin-top: 5px;
}
#savenewChallengeCoordinatorsBtn{
    border-radius:25px;
    background-color: #FFDFD6 !important;
    color: black !important;
    border:none;
    width:40%;
    margin-left: 30%;
    margin-top: 5%;
}
#no-coordinators-msg-label{
    text-align: center;
    width:60%;
    margin-left: 20%;
}
#goToSiteBtn{
    border-radius:25px;
    background-color: #FFDFD6 !important;
    color: black;
    border:none;
    width:100%;
    margin-top: 7.5px;
}
#goToSiteBtn:hover,#createChallengeBtn:hover,
#addnewChallengeCoordinatorsBtn:hover,
#savenewChallengeCoordinatorsBtn:hover,
#addGeneralCoordinatorsBtn:hover{
    background-color: #52C3FF !important;
}

@media only screen and (max-width: 1350px) {
    #goToSiteBtn{
        width:125%;
        margin-left: -20px;
    }
}