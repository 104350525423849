/* #navbar {
    background-image: linear-gradient(144deg, #F38FA2 0%, #EB5C6F 41%, #F1825C 100%); 
    position: fixed;
    z-index: 999 !important;
    width: 100%;
    --bs-navbar-padding-y:0rem;
}
img{
    vertical-align: middle;
    border-style: none;
}
.nav-logo{
    border-radius: 5rem; 
    margin: -10px 0;
}
.nav-text{
    position: absolute;
    left: 5%; 
    bottom: 15%;
    font-size: 14px; 
    font-weight: bold; 
    color: rgb(117, 92, 92); 
    font-style: italic;
}
#greetingNav{
    font-size:large ;
    margin-left: 5px;
}
.ml-4{
    margin-left: 1.5rem !important;
} */
#navbar {
    position: fixed;
    z-index: 999 !important;
    width: 100%;
    padding: 0px;
    border-bottom: 1px #e6e6e6 solid;
    background-color: white;
    --bs-navbar-padding-y:0rem;
}
.nav-logo{
    border-radius: 5rem; 
    margin: -15px 0 0 0;
}

.nav-text{
    position: absolute;
    /* top: 55px;  */
    left: 3% !important;
    right: 0 !important;
    bottom: 0;
    font-size: 12px; 
    font-weight: bold; 
    color: rgb(117, 92, 92); 
    font-style: italic;
    margin-bottom: 15px;
}

#greetingsNav{
    line-height: 21px;
    margin-top:10px;
    padding-left:1rem;
    /* font-family: "inherit";  */
}
#greetingsNavName{
    font-size: 1.2em;
    color: black;
    /* font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"; 
    font-weight: bolder; */
}
