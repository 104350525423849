.msg {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
}

.msg:last-of-type {
    margin: 0;
}

.msg-bubble {
    max-width: 450px;
    padding: 15px;
    border-radius: 15px;
    background: rgb(234, 240, 246);
    padding: 0px 12px 1px 15px;
    color: rgb(66, 91, 118);
}

.msg-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.msg-info-name {
    margin-right: 10px;
    font-weight: bold;
    font-size: 0.9em;
}

.msg-info-time {
    font-size: 0.85em;
}

.received .msg-bubble {
    border-bottom-left-radius: 0;
}

.sent {
    flex-direction: row-reverse;
}

.sent .msg-bubble {
    background: #555555;
    color: #fff;
    border-bottom-right-radius: 0;
}

.sent .msg-img {
    margin: 0 0 0 10px;
}