::-webkit-scrollbar {
  display: none;
}

ul {
  list-style-type: none;
  white-space: nowrap;
  overflow-x: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.categoriesdisplay {
  width: 100%;
  height: 80px;
  background-color: grey;
  position: fixed;
}

.categoryquestion {
  width: 100%;
  margin-bottom: 2px;
  padding: 10px;
  min-height: 80px;
  border-bottom: 1px solid #e6e6e6;
}

.dropdowncategories {
  width: 100%;
  border: none;
}

.categoryquestion span {
  margin-left: 10px;
}

#categorynames {
  border-radius: 20px;
  margin-left: 20px;
  background-color: white !important;
}

#categorynames:focus {
  background-color: lightblue !important;
}

#viewAllQuestionsBtn {
  width: 100%;
  height: 40px;
  background-color: #FFDFD6;
  border: none;
  color: black;
  border-radius: 25px;
  margin-top: 5px;
}

#AddQuestionsBtn {
  width: 100%;
  height: 40px;
  background-color: #ffffff;
  border: 1px solid #FFDFD6;
  color: black;
  border-radius: 25px;
  margin-top: 5px;
}

#viewAllQuestionsBtn:hover {
  background-color: #52C3FF !important;
}

#addquest {
  margin-left: 83%;
  margin-top: 10%;
  border-radius: 20px;
}

#btnnext {
  margin-left: 85%;
  width: 7%;
  margin-top: 2%;
  border-radius: 20px;
}

.divquestions {
  border: 2px solid black;
}

/* Button:active {
  background: olive;
} */

.clicked {
  height: 5px;
  border: 2px solid red;
  margin-top: 1%;
  color: white;
}

.unClicked {
  height: 100px;
  margin-top: 0%;
  color: white;
}


.feedback-choose-ques-div label {
  font-weight: 600;
  position: relative;
  overflow: hidden;
}

.questions-box {
  border: 1px solid slategray;
  border-radius: 15px;
  padding: 15px 5px 0 25px;
  margin: 1.5% 0 1.5% 0;
  height: 600px;
  overflow-y: auto;
}

.added-questions-box {
  border: 1px solid slategray;
  border-radius: 15px;
  padding: 10px 5px 5px 5px;
  margin: 7.5% 0 1.5% 0;
  height: 600px;
  overflow-y: auto;
}

.added-question-name {
  padding: 5px;
  border: 1px solid #d7d7d9;
  border-radius: 15px;
  font-size: 14px;
  margin-bottom: 7px;
  max-height: 125px;
  word-break: break-all;
  overflow: hidden;
}

.question_name {
  font-weight: 500;
  word-break: break-all;
}

.question-checkbox-div span {
  font-weight: 500;
}

.question-checkbox-div input {
  width: 22px;
  height: 22px;
  float: right;
  margin-top: 5px;
}

#chevronIcon {
  cursor: pointer;
  font-size: 1.1em;
  margin-left: 3px;
}

#chevronIcon:hover {
  font-size: 1.5em;
}

.viewmore-label {
  cursor: pointer;
  float: right;
  margin-top: 10px;
  font-size: 13px;
}

.viewmore-label:hover {
  font-weight: 500;
}

.option-div {
  border: 2px solid #e6e6e6;
  padding: 10px;
  border-radius: 25px;
  background-color: #ffffff;
  margin: 5px;
}

#newAnotherNewOptionBtn {
  border-radius: 25px;
  background-color: #ffffff;
  border: 2px solid #FFDFD6;
  color: black;
  margin-top: 2.5%;
}

#newAnotherNewOptionBtn:hover,
#saveQuestionBtn:hover {
  background-color: #52C3FF !important;
}

#saveQuestionBtn {
  border-radius: 25px;
  background-color: #FFDFD6;
  border: none;
  color: black;
  float: right;
}

.categorySection-div {
  margin: auto 10px;
  display: inline-block;
}

.categorySection-label.active {
  background: linear-gradient(131deg, #7175E0 0%, #4D50CB 33%, #E25BC8 100%) left bottom #fefefe no-repeat;
  background-size: 100% 3px;
  font-size: larger;
  color: black;
}

.categorySection-label {
  margin: auto 10px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  padding: 5px;
}

#editCategoriesBtn {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 5px;
  margin-left: 25px;
  cursor: pointer;
}

#editCategoriesBtn:hover {
  font-size: 23px;
  font-weight: 700;
}

.my-editor {
  height: 150px;
}

.question-category-name{
  float: right;
  font-size: 16px;
}