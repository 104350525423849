.challenge-inner-card{
    padding:15px;
    border:1px #e6e6e6 solid;
    border-radius: 25px;
    width:101%;
    float:right;
    background-color: white;
    border-left:10px solid #EB5C67;
}
#challenge-card{
    margin-top: 2.5%;
}
#openChallengeBtn{
    /* background-image: linear-gradient(131deg, #7175E0 0%, #4D50CB 33%, #E25BC8 100%); */
    background-color: #FFDFD6 !important;
    color: black !important;
    border-radius: 25px;
    border:none;
    height:35px;
    width:90%;
}
#openChallengeBtn:hover{
    background-color: #52C3FF !important;
}
.right-arrow{
    font-size: 20px;
    margin-left: 5px;
    margin-top: -3px;
}
#edit-challenge-button{
    color:blue;
    border:none;
}
#challenge-user-type{
    margin-top: -15px;
    color:darkslategrey;
}

.downloadResults{
    margin-top: 10px;
    cursor: pointer;
    width: 25px;
    height: 26px;
}

@media only screen and (max-width: 1400px) {
    #openChallengeBtn{
        width:110%;
    }
}