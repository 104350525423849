.start-challenge-div {
    height: 550px;
    margin-top: 45px;
    border-radius: 25px;
    border: solid 2px red;
    background-color: white;
    margin-left: 50px;
    margin-right: 45px;
}

.challenge-title {
    text-align: center;
    color: red;
    margin-top: 1%;
}

.challenge-desc {
    text-align: center;
    color: grey;
    margin: top 1%;
}

#start-challenge-btn {
    margin-top: 2.5%;
    width: 20%;
    margin-left: 40%;
    background-color: red;
    color: white;
}

.time-div2 {
    margin-top: 7%;
}

.time-div2 .time-label {
    color: #868786;
    text-align: center;
}

.time-div2 .org-time {
    text-align: center;
}

#participant-join-wait-label{
    text-align: center;
    margin-top: 2%;
    width:60%;
    margin-left: 20%;
    margin-bottom: -10px;
    color:grey;
}

#view-participants-btn{
    border-radius: 25px;
    background-color: #FFDFD6 !important;
    color: black;
    border: none;
    float: right;
    margin-right: 2.5%;
    margin-top: 2.5%;
    width:20%;
}
#view-participants-btn:hover,#regenerateCaptainsBtn:hover{
    background-color: #52C3FF !important;
}
#generate-teams-btn {
    margin-top: 2.5%;
    width: 20%;
    margin-left: 40%;
    background-color: red;
    color: white;
}

.challengeParticipantsmodalWidth {
    min-width: 80%;
    min-height: 50%;
}

#teams-icon {
    position: relative;
    float: right;
    margin-top: 2.5%;
    margin-right: 2.5%;
    font-size: 4em;
    border:solid 1px grey;
    border-radius: 50%;
    padding:10px;
    cursor: pointer;
}
.challengeSpinWheelmodalWidth{
    width:30%;
}
.challengeTimermodalWidth{
    width:30%;
}
#captain-confirmed-tickmark{
    float:right;
    margin-right: 15px;
    color:green;
    font-size: 2em;
}
#regenerateCaptainsBtn{
    border-radius: 25px;
    background-color: #FFDFD6 !important;
    color: black;
    border: none;
    float: right;
    margin-top: 12.5px;
}
.all-captains-confirmed-label{
    color:green;
    float:left;
}
.enrollers-head-div{
    width:100%;
    display: flex;
    justify-content: space-between;
    font-size: 1.3em;
    font-weight: 500;
}