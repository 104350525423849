.spinned-topic-info {
    margin-top: 20%;
    width: 60%;
    margin-left: 20%;
    text-align: center;
}

.spinned-topic-info h2 {
    color: red;
}

.spinned-topic-info p {
    font-size: 2em;
    margin-top:15px;
}

.spin-chat-icon {
    font-size: 40px;
    float: right;
    margin-top: -5%;
}

.slide-right {
    animation: 1s slide-right;
}


#wheel {
    /*background-color: #bfbaba8f;*/
    /*width: fit-content;*/
    height: fit-content;
    /*margin-top: 30px;*/
    float: left;
}

@media (max-width: 768px) {
    #canvas {
        width: 400px !important;
        height: 400px !important;
    }
}

@media (max-width: 1024px) {
    #canvas {
        width: 500px;
        height: 450px;
    }
}

.participant-spinwheel #wheel #canvas {
    width: 500px;
    height: 470px;
}

.generating {
    animation: showup 7s infinite;
}

.questiontopic {
    width: 0px;
    animation: reveal 7s infinite;
}

.questiontopic {
    /*   margin-left:-355px; */
    animation: slidein 5s infinite;
}

@keyframes showup {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 1;
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes slidein {
    0% {
        margin-left: 0px;
    }

    20% {
        margin-left: 0px;
    }

    35% {
        margin-left: 0px;
    }

    100% {
        margin-left: 0px;
    }
}

@keyframes reveal {
    0% {
        opacity: 0;
        width: 0px;
    }

    20% {
        opacity: 1;
        width: 0px;
    }

    30% {
        width: 355px;
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        width: 55px;
    }
}

@keyframes slide-right {
    from {
        margin-left: 70%;
        width: 100%;
    }

    to {
        margin-left: 0%;
        width: 100%;
    }
}