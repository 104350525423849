.spin-wheel-page {
  margin-top: 30px;
  border-radius: 25px;
  /* border: solid 2px red;
  background-color: white; */
  margin-left: 40px;
  margin-right: 35px;
}

#canvas {
  width: 700px;
  height: 550px;
}

.admin-spin-wheel-div {
  margin-left: 15%;
  margin-top: 7.5%;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 450px;
  width:100%;
}

#publishQuestionButton {
  border-radius: 25px;
  background-color: #FFDFD6 !important;
  color: black !important;
  border: none;
  width:60%;
  margin-top: 7.5%;
  height:50px;
}
#publishQuestionButton:hover {
  background-color: #52C3FF !important;
}
.spin-wheel-info{
  margin-left: 20%;
  width:60%;
  text-align: center;
  margin-top: 15%;
}
.spin-wheel-info>h3{
  color:red;
}
.spin-inner-wheel-info>p{
  font-size: 1.3em;
  color:grey;
  margin-top: 5%;
}
.spinwheel-question-index{
  font-size: 1.3em;
  font-weight: 500;
  margin: 2.5% 0 0 2.5%;
}