.dept-details-div-label label {
  font-size: 1.15rem;
}

.deptnames {
  border-radius: 20px;
  margin-left: 20px;
  /* background-image: linear-gradient(
    131deg,
    #7175e0 0%,
    #4d50cb 33%,
    #e25bc8 100%
  ); */
  height: 60px;
  margin-bottom: 20px;
  margin-top: 20px;
}

#addSubjectsBtn {
  border-radius: 25px;
  background-color: #FFDFD6;
  border: none;
  color: black;
  margin-top: 15px;
}

#addSubjectsBtn:hover {
  background-color: #52C3FF !important;
}

#btnsections {
  border-radius: 20px;
  width: 200px;
  margin-left: 80%;
  margin-top: 10px;
  margin-bottom: 20px;
}

.department-card {
  border-bottom: 1px solid #e6e6e6;
  min-height: 75px;
  width: 100%;
  margin-top: 5px;
}

.department-card_name {
  padding: 10px;
  width: 100%;
  cursor: pointer;
}

.department_sem-card {
  width: 100%;
  padding: 5px;
  text-align: center;
  font-weight: 550;
  border-radius: 15px;
  cursor: pointer;
  margin-top: 20px;
}

.department_sem-div-div .input-selected {
  background-color: #FFDFD6;
}

.department_sem-card:hover {
  background-color: #52C3FF;
}

#createSurveyBtn {
  border-radius: 25px;
  background-color: #FFDFD6;
  border: none;
  color: black;
  width: 80%;
  margin-top: 50px;
  margin-bottom: 25px;
}

#createSurveyBtn:hover {
  background-color: #52C3FF;
}

.dept-details-div-label .select__value-container{
  min-height: 45px;
  width: 100%;
}

.department-sem-labels label{
  font-size: 18px;
  font-weight: 500;
  margin-left: 2.5%;
}

.select-subject-label{
  width: 100%;
  padding: 5px;
  text-align: center;
  font-weight: 500;
  border-radius: 15px;
  cursor: pointer;
  margin-top: 10px;
  min-height: 65px;
}

.select-subjects-div,.select-sections-div{
  max-height: 200px;
  overflow-y: auto;
  padding: 5px;
}

.select-subjects-div label,.select-sections-div label{
  font-size: 15px;
  font-weight: 500;
}

.select-section-label{
  width: 100%;
  padding: 5px;
  text-align: center;
  font-weight: 500;
  border-radius: 15px;
  cursor: pointer;
  margin-top: 10px;
  min-height: 40px;
}

.department-name{
  width: 100%;
  background-color: #FFDFD6;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  padding: 8px;
}

.subject-selected,.section-selected,.semester-selected,.select-subject-label:hover, .select-section-label:hover{
  background-color: #52C3FF;
}

.departmentName{
  width: 100%;
  padding: 8px;
  background-color: #FFDFD6;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

#select-subject-label2{
  font-size: 16px;
  font-weight: bold;
  margin-left: 10px;
  margin-top: 20px;
}